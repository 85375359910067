.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}

@keyframes marqueeAnimation {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

.marqueeDur .js-marquee-wrapper {
  animation: 12.5417s linear 1s infinite normal none running  marqueeAnimation ;
}
.marqueeDur.paused .js-marquee-wrapper {
  animation-play-state: paused;
}

.btn::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #7ee8cc;
  z-index: 1;
  transform: scaleY(0);
  transform-origin: bottom;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: 0.2s ease-in-out;

}

.btn:hover::before{
  transform: scaleY(1);
  transform-origin: bottom;
 
}